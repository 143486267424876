<template>
  <div class="deepsel-vertical-snap-line" :style="{left: `${$store.state.snap_x}px`}"></div>
</template>

<script>
export default {
  name: "VerticalSnapLine",
}
</script>

<style scoped>
.deepsel-vertical-snap-line {
  position: fixed;
  top: 0;
  z-index: 999999999;
  height: 100%;
  border-left: 1px solid #FF00FF;
}
</style>